var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{ref:"parent-height",staticClass:"px-4 py-2",attrs:{"page":_vm.options.page,"headers":_vm.headers,"items":_vm.items,"item-key":"id","dense":"","single-select":"","height":_vm.fixedHeight - 145,"selectable-key":"id","footer-props":{ itemsPerPageOptions: [5, 10, 15] },"search":_vm.search,"server-items-length":_vm.totalItems || 0,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"dark":_vm.$vuetify.theme.dark,"item-class":_vm.itemRowBackground},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event},"update:options":_vm.paginationData},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-text-field',{ref:"searchInput",attrs:{"disabled":_vm.loading,"value":_vm.search,"clearable":"","label":_vm.$t('search')},on:{"change":function($event){return _vm.onSearchInput($event)},"click:clear":_vm.clearSearch}},[_c('v-btn',{attrs:{"slot":"append-outer","color":"primary","elevation":"0","disabled":_vm.loading},slot:"append-outer"},[_c('v-icon',[_vm._v("mdi-magnify")])],1)],1)]},proxy:true},{key:"item.call_direction",fn:function(ref){
var item = ref.item;
return [(item.call_type === 'emergency' && item.call_direction === 'incoming')?_c('v-badge',{attrs:{"color":"error","overlap":"","top":"","right":"","icon":"mdi-heart"}},[(item.call_direction === 'incoming')?_c('v-icon',{attrs:{"color":"success"}},[_vm._v(" mdi-phone-incoming ")]):_vm._e()],1):_vm._e(),(item.call_direction === 'incoming' && item.call_type !== 'emergency')?_c('v-icon',{attrs:{"color":"success"}},[_vm._v(" mdi-phone-incoming ")]):_vm._e(),(item.call_direction === 'outgoing')?_c('v-icon',{attrs:{"color":"accent"}},[_vm._v(" mdi-phone-outgoing ")]):_vm._e()]}},{key:"item.call_from",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.call_from)+" ")]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(new Date(item.created_at).toLocaleString())+" ")]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('call-back-button',{attrs:{"dialNumber":item.call_from}}),_c('call-transfer-button',{attrs:{"dialNumber":item.call_from}}),_c('call-detail-button',{attrs:{"data":item,"reason":"cdr"}})]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }
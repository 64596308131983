<template>
  <div class="chat-one-gl-widget">
    <div class="chat-one-header">
      <div v-if="config.title" class="chat-one-title">
        {{ title }}
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              :disabled="isTDDDisabled"
              class="no-case ml-3 success"
              icon
              small
              @click="startTTYDetection()"
              v-on="on"
            >
              <v-icon color="white" small>mdi-deskphone</v-icon>
            </v-btn>
          </template>
          <span>{{ this.$t("start_tdd") }}</span>
        </v-tooltip>
      </div>
    </div>
    <div class="chat-one-body">
      <div
        v-for="(message, index) in messages"
        :key="index"
        :class="{ me: message.sender === messageTypes.own }"
        class="chat-one-message"
        style="white-space: pre-wrap;"
      >
        <span v-if="message.message.type && message.message.type === 'VIDEO'">
          <a :href="message.message.body" target="_blank">{{
            message.message.body
          }}</a>
        </span>
        <span v-else>
          {{ message.message }}
        </span>
      </div>
    </div>

    <v-expand-transition>
      <div
        v-if="suggestedTTYMessages.length > 0"
        class="suggested-list-wrapper"
      >
        <v-list class="suggested-list" dense max-height="120" width="70%">
          <v-list-item-group v-model="onArrowActiveListModel">
            <v-list-item
              v-for="(item, i) in suggestedTTYMessages"
              :key="i"
              :value="i"
              class="suggested-list-item"
              @click.left="insertSuggestedTTYMessage(item.textLine)"
            >
              {{ item.textLine }}
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </div>
    </v-expand-transition>
    <div class="chat-one-footer">
      <textarea
        v-model="message"
        @input="handleMessageTyping"
        @keyup="sendRTT"
        @keydown.enter.prevent="checkSuggestionsBeforeSendingMessage"
        @keydown.up.prevent="handleArrowMovementOnList('up')"
        @keydown.down.prevent="handleArrowMovementOnList('down')"
      />
      <div>
        <div class="v-icon mdi mdi-send send" @click="sendMessage"></div>
        <div
          class="v-icon mdi mdi-video send"
          @click="getVideoConferenceUrl"
        ></div>
        <div
          class="v-icon mdi mdi-message-text send"
          @click="sendSMSMessage"
        ></div>
      </div>
    </div>
  </div>
</template>
<script>
import apiService from '@/modules/api/csp'
import {
  chatSizes,
  chatStates,
  messageTypes
} from '@/app/widgets/chat-widget/constants/constants.js'
import { mapGetters } from 'vuex'

export default {
  name: 'ChatComponent',
  props: [
    'count',
    'no-minimize-button',
    'no-maximize-button',
    'no-title',
    'no-close-button',
    'draggable',
    'modal'
  ],
  data: function (vm) {
    return {
      data: {},
      left: 80,
      title: vm.$t('here_will_go_title'),
      curSize: chatSizes.NORMAL,
      message: '',
      sendRtt: false,
      messages: [],
      curState: chatStates.MINIMIZED,

      callIDs: [],

      chatSizes,
      chatStates,
      messageTypes,

      pager: {
        curPage: 1,
        loading: false,
        totalPages: 2
      },

      translationLanguage: 'en',
      isTDDDisabled: false,
      isTDD: false,

      messageTypingInterval: null,
      suggestedTTYMessages: [],
      serverTTYMessages: [],
      onArrowActiveListModel: null,
      socketListenerRegistered: false
    }
  },
  computed: {
    config () {
      const config = {
        buttons: {},
        position: 'relative'
      }

      if (this.$props.modal !== undefined) {
        config.draggable = true
        config.position = 'fixed'

        config.title = true
        config.buttons.closeButton = true
        config.buttons.minimizeButton = true
        config.buttons.maximizeButton = true
      }

      config.title = !(this.$props.noTitle !== undefined)
      config.draggable = !(this.$props.draggable !== undefined)
      config.buttons.closeButton = !(this.$props.noCloseButton !== undefined)
      config.buttons.minimizeButton = !(
        this.$props.noMinimizeButton !== undefined
      )
      config.buttons.maximizeButton = !(
        this.$props.noMaximizeButton !== undefined
      )

      return config
    },
    ...mapGetters('settings', ['sipDefaultAddressGetter', 'getGlobalSetting']),
    ...mapGetters('adr', ['subscriberInfoGetter']),
    computedSipAddressRealm () {
      return this.sipDefaultAddressGetter?.realm ?? null
    },
    phoneNumber () {
      return this.subscriberInfoGetter?.SubscriberData?.vcard?.tel?.uri?.replace(
        'tel:',
        ''
      )
    },
    isChatEnabled () {
      return process.env.VUE_APP_ENABLE_CHAT === 'true'
    }
  },
  methods: {
    checkSuggestionsBeforeSendingMessage () {
      if (typeof this.onArrowActiveListModel === 'number') {
        const suggestedText =
          this.suggestedTTYMessages[this.onArrowActiveListModel]

        this.message = suggestedText.textLine
        this.suggestedTTYMessages = []
        this.onArrowActiveListModel = null
      } else {
        this.sendMessage()
      }
    },
    handleArrowMovementOnList (movement) {
      if (this.suggestedTTYMessages.length === 0) return

      if (typeof this.onArrowActiveListModel !== 'number') {
        this.onArrowActiveListModel = 0
        return
      }

      if (movement === 'up') {
        if (this.onArrowActiveListModel === 0) {
          this.onArrowActiveListModel = 0
          return
        }
        this.onArrowActiveListModel--
      }
      if (movement === 'down') {
        if (
          this.suggestedTTYMessages.length - 1 ===
          this.onArrowActiveListModel
        ) {
          this.onArrowActiveListModel = this.suggestedTTYMessages.length - 1
          return
        }
        this.onArrowActiveListModel++
      }
    },
    handleMessageTyping () {
      if (this.messageTypingInterval) {
        clearInterval(this.messageTypingInterval)
      }
      this.messageTypingInterval = setTimeout(async () => {
        if (this.message) {
          this.suggestedTTYMessages = await this.filterSuggestedTTYMessages(
            this.message
          )
        } else {
          this.suggestedTTYMessages = []
          this.onArrowActiveListModel = null
        }
      }, 285)
    },
    async filterSuggestedTTYMessages (text) {
      return this.serverTTYMessages.filter((item) =>
        item.textLine.toLowerCase().includes(text.toLowerCase())
      )
    },
    insertSuggestedTTYMessage (text) {
      this.message = text
      this.suggestedTTYMessages = []
      this.onArrowActiveListModel = null
    },
    async startTTYDetection () {
      for (let i = 0; i < this.callIDs.length; i++) {
        await apiService.egress.startTddDetection(
          this.sipDefaultAddressGetter.account_id,
          this.callIDs[[i]]
        )
      }

      this.isTDD = true
      this.isTDDDisabled = true
    },
    minimize () {
      this.curState =
        this.curState === this.chatStates.MINIMIZED
          ? this.chatStates.MAXIMIZED
          : this.chatStates.MINIMIZED
    },
    maximize () {
      this.curSize =
        this.curSize === this.chatSizes.NORMAL
          ? this.chatSizes.EXPAND
          : this.chatSizes.NORMAL
    },
    close () {
      this.$emit('remove', this.count)

      // destroy the vue listeners, etc
      this.$destroy()
      // remove the element from the DOM
      this.$el.parentNode.removeChild(this.$el)
    },
    joinCall () {
      this.callIDs.forEach((id) => {
        this.$socket.emit('join-call', id)
      })
    },
    async receivedMessage (message) {
      this.sendRtt = message.rtt

      const messages = this.messages.filter(
        (item) => !(!item.complete && item.sender === messageTypes.other)
      )

      const decodedMessage = await this.getMessage(message.message, true)

      this.messages = [
        {
          msg_id: message.msg_id,
          sender: messageTypes.other,
          complete: message.complete,
          message: decodedMessage
        },
        ...messages
      ]

      if (message.rtt === false) { // Optional auto-reply for first msrp message
        const replyEnabled = this.getGlobalSetting('MSRP_enable_auto_reply')?.value.active
        const replyContent = this.getGlobalSetting('MSRP_enable_auto_reply_content')?.value.text

        if (this.messages.length === 1 && message.complete && replyEnabled && replyContent) {
          this.message = replyContent
          await this.sendMessage()
        }
      }
    },
    sendRTT: function (e) {
      if (e.keyCode === 16 || e.key.length !== 1) return

      if (this.isTDD) {
        this.sendTDD(e.key)

        return
      }

      if (!this.sendRtt) return

      this.emitEevnt(e.key, false)
    },
    sendTDD: function (char) {
      this.callIDs.forEach((callID) => {
        apiService.egress.sendTddText(
          this.sipDefaultAddressGetter.account_id,
          callID,
          encodeURIComponent(char)
        )
      })
    },
    sendMessage: async function (e, mes) {
      if (!mes && !this.message) return

      this.messages = [
        {
          sender: messageTypes.own,
          message: mes || this.message
        },
        ...this.messages
      ]

      const message = mes
        ? mes.body
        : await this.getMessage(this.message, false)

      if (!mes) this.message = ''

      if (!mes && this.isTDD) {
        this.sendTDD('\n')

        return
      }

      this.emitEevnt(this.sendRtt ? '\n' : message.trim() + '\n', true)
    },
    emitEevnt (message, complete, event = 'send_message') {
      this.callIDs.forEach((callID) => {
        this.$socket.emit(event, {
          message,
          complete,
          rtt: this.sendRTT,
          sip: this.$sip.sipAddress,
          call_id: callID,
          phoneNumber: this.phoneNumber
        })
      })
    },
    setLegA (data) {
      const items = data.filter(
        (item) =>
          item.authorizing_type === 'open_auth' &&
          [this.data.from, '1' + this.data.from, '+1' + this.data.from].indexOf(
            item.caller_id_number
          ) > -1
      )

      if (!items.length) return

      this.callIDs = [...items.map((item) => item.uuid), ...this.callIDs]
    },
    setLegAV2 (data) {
      const items = data.filter(
        (item) =>
          item.uuid === this.$sip.answeredCallSession._request.call_id &&
          item.other_leg
      )

      if (!items.length) return

      this.callIDs = [...items.map((item) => item.other_leg), ...this.callIDs]
    },
    setLegOutgoingLegA () {
      this.callIDs.push(this.$sip.answeredCallSession._request.call_id)
    },
    async getMessage (message, detectLanguage) {
      if (message.trim && message.trim() === '') return message

      const parsed = this.parseMessage(message)

      let response = await this.translateMessage(parsed, detectLanguage)

      response = await response.json()

      if (!response.data || !response.data.translations) return parsed

      if (detectLanguage) {
        this.translationLanguage =
          response.data.translations[0].detectedSourceLanguage
      }

      return response.data.translations[0].translatedText
    },
    async translateMessage (message, incomming) {
      let url = `https://translation.googleapis.com/language/translate/v2?key=${process.env.VUE_APP_GOOGLE_API_KEY}`

      url += '&q=' + encodeURIComponent(message)
      url += `&target=${incomming ? 'en' : this.translationLanguage}`

      return await fetch(url)
    },
    parseMessage (message) {
      if (!message) return

      const parsed = new DOMParser().parseFromString(message, 'text/html')
      const cnt = parsed.body.childElementCount

      for (let i = 0; i < cnt; i++) {
        const el = parsed.body.children[i]

        if (el.tagName.toLowerCase() !== 'p') continue

        return (el.innerText || '').trim()
      }

      return message
    },
    getVideoConferenceUrl () {
      this.sendMessage(undefined, {
        type: 'VIDEO',
        body: `${
          window.location.origin
        }/conference/video/${crypto.randomUUID()}`
      })
    },
    async sendSMSMessage () {
      const message = await this.getMessage(this.message, false)

      this.message = ''

      this.emitEevnt(message.trim() + '\n', true, 'send_sms')
    },
    collentData (data) {
      const index = this.$sip.session.findIndex(
        (item) => item.id === data.sessionId
      )

      let correlationID = null

      if (
        this.$sip.session[index]._request.headers[
          'X-Ecallmgr-Call-Correlation-Id'
        ] &&
        this.$sip.session[index]._request.headers[
          'X-Ecallmgr-Call-Correlation-Id'
        ].length > 0
      ) {
        correlationID =
          this.$sip.session[index]._request.headers[
            'X-Ecallmgr-Call-Correlation-Id'
          ][0].raw
      }

      return {
        sessionId: this.$sip.session[index].id,
        incidentId: this.incidentId,
        direction: this.$sip.session[index].direction,
        from: this.$sip.session[index].remote_identity.uri.user,
        fromName: this.$sip.session[index].remote_identity.display_name,
        to: this.$sip.session[index].local_identity.uri.user,
        toName: this.$sip.session[index].local_identity.display_name,
        established: this.$sip.session[index].isEstablished(),
        progress: this.$sip.session[index].isInProgress(),
        ended: this.$sip.session[index].isEnded(),
        callId: this.$sip.session[index]._request.call_id,
        localHold: this.$sip.session[index].isOnHold().local,
        remoteHold: this.$sip.session[index].isOnHold().remote,
        answered: false,
        audio: {
          level: 85,
          microphone: true,
          volume: true
        },
        correlationID
      }
    }
  },
  watch: {
    callIDs () {
      this.joinCall()
    },
    async computedSipAddressRealm () {
      if (!this.computedSipAddressRealm) return

      const { data: predefinedMessages } = await apiService.ttyMessages.getList(
        {
          realm: this.computedSipAddressRealm
        }
      )
      this.serverTTYMessages = predefinedMessages
    }
  },

  async mounted () {
    this.$event.listen('openOutgoingChat', (event) => {
      this.setLegOutgoingLegA()
    })

    this.$event.listen('openChat', (event) => {
      this.callIDs = []

      this.isTDD = false
      this.isTDDDisabled = false

      this.message = ''
      this.messages = []
      // if (!this.isChatEnabled) return

      this.data = this.collentData(event.eventData)
      const inviteLegA = event?.eventData?.session?._request?.headers?.Contact[0]?.parsed?._uri?._parameters['ippbx-uuid']
      this.title = this.data.fromName ? this.data.fromName : this.data.from

      this.joinCall()

      if (inviteLegA) {
        this.callIDs.push(inviteLegA)
        if (!this.socketListenerRegistered) {
          this.$socket.off('received_message', this.receivedMessage)
          this.$socket.on('received_message', this.receivedMessage)
          this.socketListenerRegistered = true
        }
      } else {
        apiService.egress
          .getChannels(this.sipDefaultAddressGetter.account_id)
          .then((response) => {
            if (response.status !== 200) return

            this.setLegA(response.data.data)
            this.setLegAV2(response.data.data)

            this.callIDs = this.callIDs.filter(
              (value, index, array) => array.indexOf(value) === index
            )
            if (!this.socketListenerRegistered) {
              this.$socket.off('received_message', this.receivedMessage)
              this.$socket.on('received_message', this.receivedMessage)
              this.socketListenerRegistered = true
            }
          })
      }
    })
  }
}
</script>

<style lang="scss" scoped>
@import "@/sass/components/chat";

::-webkit-scrollbar {
  width: 5px;
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: #767a8b !important;
}
</style>

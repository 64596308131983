<template>
  <v-data-table class="px-4 py-2" :page="options.page" :headers="headers" :items="items" item-key="id" dense single-select
    :height="fixedHeight - 145" ref="parent-height" selectable-key="id"
    :footer-props="{ itemsPerPageOptions: [5, 10, 15] }" :search="search" :server-items-length="totalItems || 0"
    :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :dark="$vuetify.theme.dark" @update:options="paginationData"  :item-class="itemRowBackground" >
    <template v-slot:[`top`]>
      <v-text-field ref="searchInput" :disabled="loading" :value="search" clearable v-on:change="onSearchInput($event)"
        @click:clear="clearSearch" :label="$t('search')">
        <v-btn slot="append-outer" color="primary" elevation="0" :disabled="loading">
          <v-icon>mdi-magnify</v-icon>
        </v-btn>
      </v-text-field>
    </template>

    <template v-slot:[`item.call_direction`]="{ item }">

      <v-badge
        color="error"
        overlap
        top
        right
        icon="mdi-heart"
        v-if="item.call_type === 'emergency' && item.call_direction === 'incoming'"
      >
        <v-icon v-if="item.call_direction === 'incoming'" color="success">
          mdi-phone-incoming
        </v-icon>
      </v-badge>
      <v-icon v-if="item.call_direction === 'incoming' && item.call_type !== 'emergency'" color="success">
        mdi-phone-incoming
      </v-icon>
      <v-icon v-if="item.call_direction === 'outgoing'" color="accent">
        mdi-phone-outgoing
      </v-icon>
    </template>

    <template v-slot:[`item.call_from`]="{ item }">
      {{ item.call_from }}
    </template>

    <template v-slot:[`item.created_at`]="{ item }">
      {{ new Date(item.created_at).toLocaleString() }}
    </template>

    <template v-slot:[`item.action`]="{ item }">
      <call-back-button :dialNumber="item.call_from" />
      <call-transfer-button :dialNumber="item.call_from" />
      <call-detail-button :data="item" reason="cdr" />
    </template>
  </v-data-table>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
/* Mixins */
import parentHeight from '@/mixins/parent-height.mixin'
/* Buttons */
import CallBackButton from '@/app/shared/buttons/CallBackButton'
import CallTransferButton from '@/app/shared/buttons/CallTransferButton'
import CallDetailButton from '@/app/shared/buttons/CallDetailButton'

export default {
  name: 'CallHistoryComponent',
  mixins: [parentHeight],
  props: ['parentElement'],
  components: {
    CallBackButton,
    CallTransferButton,
    CallDetailButton
  },
  data: (vm) => ({
    search: '',
    sortBy: 'created_at',
    sortDesc: true,
    options: {
      page: 1,
      itemsPerPage: 15
    },
    headers: [
      { text: '', value: 'call_direction', sortable: false, width: '3%' },
      { text: vm.$t('Number'), value: 'call_from', sortable: true },
      { text: vm.$t('Date'), value: 'created_at', sortable: true },
      { text: vm.$t('Action'), value: 'action', sortable: false, width: '25%' }
    ]
  }),

  computed: {
    ...mapGetters('callHistory', [
      'callHistoryItemsGetter',
      'callHistoryTotalItemsGetter',
      'callHistoryLoadingGetter'
    ]),
    items () {
      return this.callHistoryItemsGetter
    },
    totalItems () {
      return this.callHistoryTotalItemsGetter
    },
    loading () {
      return this.callHistoryLoadingGetter
    }
  },

  methods: {
    ...mapActions('callHistory', [
      'fetchCallHistoryAction'
    ]),
    async fetchPaginatedCallHistoryItems () {
      const { sortBy, sortDesc, search, options } = this
      const params = {
        limit: options.itemsPerPage,
        page: options.page,
        callFrom: search,
        sortBy,
        sortOrder: sortDesc ? 'desc' : 'asc'
      }
      await this.fetchCallHistoryAction(params)
    },
    onSearchInput (newInputSearch) {
      this.search = newInputSearch
      this.options.page = 1
      this.fetchPaginatedCallHistoryItems()
    },
    paginationData (newOptions) {
      this.options = { ...this.options, ...newOptions }
      this.fetchPaginatedCallHistoryItems()
    },
    clearSearch () {
      this.search = ''
      this.fetchPaginatedCallHistoryItems()
    },
    sortByDate () {
      this.sortDesc = !this.sortDesc
      this.fetchPaginatedCallHistoryItems()
    },
    itemRowBackground (item) {
      return item.call_type === 'emergency' ? 'emergency_color' : 'admin_color'
    }
  },

  created () {
    this.fetchPaginatedCallHistoryItems()
  },

  mounted () {
    this.$event.listen('gl_resize', (e) => {
      this.modifyHeight()
    })
  }
}
</script>

<style scoped></style>
<style>
.emergency_color {
  background-color: rgb(73 20 20)
}
.admin_color {
}
</style>

import EmergencyCallDataParser from '@/modules/api/csp/parsers/emergency-call-data.parser'
import heldXmlParser from '@/modules/api/csp/parsers/held/held-xml.parser'
import LogEventEnum from '@/plugins/i3-logger/enums/i3-logger.enum'
import EventDispatcher from '@/modules/event/event-dispatcher.module'
const event = new EventDispatcher()
export default function heldParser (session) {
  return {

    emergencyCallDataParser: null,

    init () {
      this.emergencyCallDataParser = new EmergencyCallDataParser().parseGeoLocation(session?._request?.headers?.Geolocation)
    },

    async parse (useRef) {
      this.init()
      return {
        held: session._request?.multipart?.['application/pidf+xml']?.[0] && !useRef
          ? this.heldByVal()
          : await this.heldByRef()
      }
    },

    async heldByRef () {
      this.logEventDispatcher(session, LogEventEnum.LocationQueryLogEvent)
      return this.emergencyCallDataParser?.location_url ? await heldXmlParser().parse(this.emergencyCallDataParser?.location_url) : null
    },

    heldByVal () {
      this.logEventDispatcher(session, LogEventEnum.LocationResponseLogEvent, session._request.multipart['application/pidf+xml']?.[0], '', true)
      return heldXmlParser().parseHeld(session._request.multipart['application/pidf+xml']?.[0])
    },

    logEventDispatcher (session, logEvent, responseData = null, subEventName = null, baVal = false) {
      event.dispatch('i3-held-logs', {
        session: session,
        logEventName: logEvent,
        responseData: responseData,
        subEventName: subEventName,
        baVal: baVal
      })
    }
  }
}

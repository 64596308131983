import EmergencyCallDataParser from '@/modules/api/csp/parsers/emergency-call-data.parser'
import adrXmlParser from '@/modules/api/csp/parsers/adr/adr-xml.parser'
import LogEventEnum from '@/plugins/i3-logger/enums/i3-logger.enum'
import EventDispatcher from '@/modules/event/event-dispatcher.module'

const event = new EventDispatcher()

export default function adrParser (session) {
  return {

    emergencyCallDataParser: null,

    init () {
      this.emergencyCallDataParser = new EmergencyCallDataParser().parseAdr(session?._request?.headers?.['Call-Info'])
    },

    async parse () {
      this.init()
      return {
        comment: await this.comment(),
        subscriberInfo: await this.subscriberInfo(),
        serviceInfo: await this.serviceInfo(),
        deviceInfo: await this.deviceInfo(),
        providerInfo: await this.providerInfo()
      }
    },

    async comment () {
      if (session._request.multipart && session._request.multipart['application/emergencycalldata.comment+xml']) {
        this.logEventDispatcher(session, LogEventEnum.AdditionalDataResponseLogEvent, session._request.multipart['application/emergencycalldata.comment+xml'][0], 'Comment', true)
        return adrXmlParser().parseADR(session._request.multipart['application/emergencycalldata.comment+xml'][0], 'Comment')
      }

      this.logEventDispatcher(session, LogEventEnum.AdditionalDataQueryLogEvent, '', 'comment_url')
      return this.emergencyCallDataParser?.comment_url ? await adrXmlParser().parse(this.emergencyCallDataParser?.comment_url, 'Comment') : null
    },

    async subscriberInfo () {
      if (session._request.multipart && session._request.multipart['application/emergencycalldata.subscriberinfo+xml']) {
        this.logEventDispatcher(session, LogEventEnum.AdditionalDataResponseLogEvent, session._request.multipart['application/emergencycalldata.subscriberinfo+xml'][0], 'SubscriberInfo', true)
        return adrXmlParser().parseADR(session._request.multipart['application/emergencycalldata.subscriberinfo+xml'][0], 'SubscriberInfo')
      }

      this.logEventDispatcher(session, LogEventEnum.AdditionalDataQueryLogEvent, '', 'subscriber_info_url')
      return this.emergencyCallDataParser?.subscriber_info_url ? await adrXmlParser().parse(this.emergencyCallDataParser?.subscriber_info_url, 'SubscriberInfo') : null
    },

    async serviceInfo () {
      if (session._request.multipart && session._request.multipart['application/emergencycalldata.serviceinfo+xml']) {
        this.logEventDispatcher(session, LogEventEnum.AdditionalDataResponseLogEvent, session._request.multipart['application/emergencycalldata.serviceinfo+xml'][0], 'ServiceInfo', true)
        return adrXmlParser().parseADR(session._request.multipart['application/emergencycalldata.serviceinfo+xml'][0], 'ServiceInfo')
      }

      this.logEventDispatcher(session, LogEventEnum.AdditionalDataQueryLogEvent, '', 'service_info_url')
      return this.emergencyCallDataParser?.service_info_url ? await adrXmlParser().parse(this.emergencyCallDataParser?.service_info_url, 'ServiceInfo') : null
    },

    async deviceInfo () {
      if (session._request.multipart && session._request.multipart['application/emergencycalldata.deviceinfo+xml']) {
        this.logEventDispatcher(session, LogEventEnum.AdditionalDataResponseLogEvent, session._request.multipart['application/emergencycalldata.deviceinfo+xml'][0], 'DeviceInfo', true)
        return adrXmlParser().parseADR(session._request.multipart['application/emergencycalldata.deviceinfo+xml'][0], 'DeviceInfo')
      }

      this.logEventDispatcher(session, LogEventEnum.AdditionalDataQueryLogEvent, '', 'device_info_url')
      return this.emergencyCallDataParser?.device_info_url ? await adrXmlParser().parse(this.emergencyCallDataParser?.device_info_url, 'DeviceInfo') : null
    },

    async providerInfo () {
      if (session._request.multipart && session._request.multipart['application/emergencycalldata.providerinfo+xml']) {
        this.logEventDispatcher(session, LogEventEnum.AdditionalDataResponseLogEvent, session._request.multipart['application/emergencycalldata.providerinfo+xml'][0], 'ProviderInfo', true)
        return adrXmlParser().parseADR(session._request.multipart['application/emergencycalldata.providerinfo+xml'][0], 'ProviderInfo')
      }
      this.logEventDispatcher(session, LogEventEnum.AdditionalDataQueryLogEvent, '', 'provider_info_url')
      return this.emergencyCallDataParser?.provider_info_url ? await adrXmlParser().parse(this.emergencyCallDataParser?.provider_info_url, 'ProviderInfo') : null
    },

    logEventDispatcher (session, logEvent, responseData, subEventName, baVal = false) {
      event.dispatch('i3-adr-logs', {
        session: session,
        logEventName: logEvent,
        responseData: responseData,
        subEventName: subEventName,
        baVal: baVal
      })
    }
  }
}

import apiService from '@/modules/api/csp'
import { XMLParser } from 'fast-xml-parser'
import EventDispatcher from '@/modules/event/event-dispatcher.module'
import LogEventEnum from '@/plugins/i3-logger/enums/i3-logger.enum'

const event = new EventDispatcher()

export default function heldXmlParser () {
  return {

    locationResult: {},

    async parse (resource) {
      try {
        return await apiService.heldApiModule.get(resource).then(async data => {
          event.dispatch('i3-held-logs', {
            session: window.answeredCallSession,
            logEventName: LogEventEnum.LocationResponseLogEvent,
            responseData: data?.data,
            subEventName: '',
            baVal: ''
          })
          return this.parseHeld(data?.data)
        }).catch(() => {
          event.dispatch('updated-held', {})
        })
      } catch (e) {
        console.log(e)
      }
    },

    parseHeld (data) {
      if (!data) {
        console.error('No XML data provided to parseHeld.')
        return {}
      }

      let jsonObj
      try {
        const parseOptions = { ignoreAttributes: false, removeNSPrefix: true }
        const parser = new XMLParser(parseOptions)
        jsonObj = parser.parse(data)
      } catch (err) {
        console.error('Failed to parse XML:', err)
        return {}
      }
      this.locationResult = {}
      this.getNestedObject(jsonObj)

      event.dispatch('updated-held', { ...this.locationResult })
      console.log('Parsed data', this.locationResult)
      return this.locationResult
    },

    getNestedObject (locationInfo) {
      if (!locationInfo || typeof locationInfo !== 'object') return
      for (const key in locationInfo) {
        const val = locationInfo[key]
        if (val && typeof val === 'object') {
          this.getNestedObject(val)
        } else {
          if (key === 'pos') {
            this.locationResult.lat = val.split(' ')[0]
            this.locationResult.lng = val.split(' ')[1]
            this.locationResult.altitude = val.split(' ')[2]
          } else this.locationResult[key] = val.toString()
        }
      }
    }

  }
}

<template>
  <v-container v-if="layoutGetter" fluid  >

    <v-row class="d-flex pa-0 px-1">
      <v-col  class="pa-0">
        <user-dashboard-layout-component v-if="type=='user'"></user-dashboard-layout-component>
        <global-dashboard-layout-component v-if="type=='global'"></global-dashboard-layout-component>
        <realm-dashboard-layout-component v-if="type=='realm'"></realm-dashboard-layout-component>
      </v-col>
    </v-row>

    <dial-pad-button v-if="authUserGetter && $can('can_see_dial_pad')"></dial-pad-button>
    <live-recording-component />
    <call-detail-tabs-component />
    <div class="chats-holder">
      <chat-component v-for="(chat, index) in chats" :key="chat.incidentId + '_' + chat.callId" :data="chat"
        :count="index" :modal="true" @remove="removeChat">
      </chat-component>
    </div>
  </v-container>
</template>

<script>
/* Views */
import DialPadButton from '@/app/widgets/dial-pad-widget/components/buttons/DialPadButton'
import ChatComponent from '@/app/widgets/chat-widget/components/ChatComponent'
import LiveRecordingComponent from '@/app/widgets/live-recording-widget/components/LiveRecordingComponent'

/* Components */
import CallDetailTabsComponent from '@/app/widgets/call-detail-tabs-widget/components/CallDetailTabsComponent'

/* Mixins */
import sipEventsMixin from '@/mixins/sip/sip-events.mixin'
import readyStateMixin from '@/mixins/sip/ready-state.mixin'

import { mapActions, mapGetters } from 'vuex'

import indexedDBSyncService from '@/util/indexedDB-sync-service'
import GlobalDashboardLayoutComponent from '@/app/widgets/dashboard-widget/components/layout/GlobalDashboardLayoutComponent'
import RealmDashboardLayoutComponent from '@/app/widgets/dashboard-widget/components/layout/RealmDashboardLayoutComponent'
import UserDashboardLayoutComponent from '@/app/widgets/dashboard-widget/components/layout/UserDashboardLayoutComponent'

import cadMixin from '@/mixins/cad.mixin.js'
import I3Logger from '@/plugins/i3-logger/I3Logger.plugin'

export default {
  name: 'DashboardPage',
  mixins: [sipEventsMixin, readyStateMixin, cadMixin],
  components: {
    RealmDashboardLayoutComponent,
    GlobalDashboardLayoutComponent,
    UserDashboardLayoutComponent,
    ChatComponent,
    LiveRecordingComponent,
    DialPadButton,
    CallDetailTabsComponent
  },

  props: {
    type: {
      type: String,
      default: 'user'
    }
  },

  data: () => ({
    chats: []
  }),

  computed: {
    ...mapGetters('global', ['authUserGetter']),
    ...mapGetters('layout', ['layoutGetter']),
    ...mapGetters('settings', ['sipDefaultAddressGetter', 'getGlobalSetting'])
  },
  methods: {
    ...mapActions('layout', [
      'loadLayoutAction',
      'loadLayoutsAction'
    ]),
    ...mapActions('global', [
      'loadRingtonesAction',
      'loadTranslationsAction'
    ]),
    ...mapActions('settings', [
      'egressAuthAction',
      'getAccountsAction',
      'sipAddressesAction',
      'settingsProxyAction',
      'sipDefaultAddressAction',
      'loadUserSettingsAction',
      'loadGlobalSettingsAction',
      'prerecordedAudioAction'
    ]),
    ...mapActions('contactBook', [
      'getContactBookAction',
      'getContactGroupsAction'
    ]),
    ...mapActions('speedDial', [
      'getSpeedDialButtonsAction'
    ]),
    ...mapActions('fccMasterRegistry', [
      'getFccMasterRegistryAction'
    ]),
    ...mapActions('logo', [
      'loadLogoAction'
    ]),
    ...mapActions('callLines', [
      'getCustomCallLinesAction'
    ]),
    addNewChat (payload) {
      this.chats = [payload]
    },
    clearChat () {
      this.chats = []
    },
    removeChat (index) {
      this.chats.splice(index, 1)
    },
    collentData (data) {
      const index = this.$sip.session.findIndex(item => item.id === data.sessionId)

      let correlationID = null

      if (
        this.$sip.session[index]._request.headers['X-Ecallmgr-Call-Correlation-Id'] &&
        this.$sip.session[index]._request.headers['X-Ecallmgr-Call-Correlation-Id'].length > 0
      ) {
        correlationID = this.$sip.session[index]._request.headers['X-Ecallmgr-Call-Correlation-Id'][0].raw
      }

      return {
        sessionId: this.$sip.session[index].id,
        incidentId: this.incidentId,
        direction: this.$sip.session[index].direction,
        from: this.$sip.session[index].remote_identity.uri.user,
        fromName: this.$sip.session[index].remote_identity.display_name,
        to: this.$sip.session[index].local_identity.uri.user,
        toName: this.$sip.session[index].local_identity.display_name,
        established: this.$sip.session[index].isEstablished(),
        progress: this.$sip.session[index].isInProgress(),
        ended: this.$sip.session[index].isEnded(),
        callId: this.$sip.session[index]._request.call_id,
        localHold: this.$sip.session[index].isOnHold().local,
        remoteHold: this.$sip.session[index].isOnHold().remote,
        answered: false,
        audio: {
          level: 85,
          microphone: true,
          volume: true
        },
        correlationID
      }
    },
    sync () {
      indexedDBSyncService.sync([
        'cdrs',
        'speedDial',
        'contactBooks',
        'contactGroups'
      ])
    },
    async configureSocket () {
      if (!this.sipDefaultAddressGetter || !this.sipDefaultAddressGetter.id) return

      this.$socket.emit('join-realm', {
        realm: this.sipDefaultAddressGetter.realm,
        userID: this.sipDefaultAddressGetter.sip_address
      })

      this.$event.listen('incoming-call-answer', () => {
        this.setOnCallState()
      })

      this.$event.listen('outgoing-confirmed', () => {
        this.setOnCallState()
      })

      this.$event.listen('incoming-ended', () => {
        this.setReadySate()
      })

      this.$event.listen('outgoing-ended', () => {
        this.setReadySate()
      })

      this.$event.listen('i3-adr-logs', (event) => {
        new I3Logger().i3LogEvent(event.eventData.session, event.eventData.logEventName, event.eventData.responseData, event.eventData.subEventName, event.eventData.baVal)
      })
      this.$event.listen('i3-held-logs', (event) => {
        new I3Logger().i3LogEvent(event.eventData.session, event.eventData.logEventName, event.eventData.responseData, event.eventData.subEventName, event.eventData.baVal)
      })

      await this.getCustomCallLinesAction(this.sipDefaultAddressGetter.realm)
      await this.loadLogoAction()
      await this.loadGlobalSettingsAction().catch()
    }
  },
  async mounted () {
    window.__addNewChat = this.addNewChat

    await this.egressAuthAction()
    await this.getAccountsAction()
    await this.loadUserSettingsAction()
    await this.loadLayoutsAction()
    await this.loadLayoutAction()

    await this.getSpeedDialButtonsAction()
    await this.sipDefaultAddressAction()
    await this.loadTranslationsAction()

    await this.configureSocket()

    this.$timer.add(this.sync, 10 * 60)

    await this.loadRingtonesAction()

    await this.sipAddressesAction(this.authUserGetter.id)
    await this.settingsProxyAction()
    await this.prerecordedAudioAction()

    this.setNotReadyState()

    const type = window.performance.getEntriesByType('navigation')[0]?.type

    if (type) this.sendActivityLog(type)

    window.onbeforeunload = () => {
      this.setNotReadyState()
    }
  }
}
</script>

<style scoped>

</style>
